.variants-container {
  margin-top: 48px;
}

.row {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 64px;
}

.card {
  font-weight: 300;
  line-height: 130%;
  width: 288px;
  min-height: 258px;
  padding: 16px 18px;

  p {
    margin: 0;
  }

  pre {
    margin: auto;
  }
}

.button {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 0 16px;
  height: 64px;
  color: var(--default-opaque-neutrals-white, #FFF);
  font-size: 24px;
  font-weight: 600;
  line-height: 130%;
}

.week {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.week-title {
  color: var(--default-opaque-neutrals-gray-80, #4A4A4A);
  font-size: 24px;
  font-weight: 600;
  line-height: 130%;
}

.week-subtitle {
  color: var(--default-opaque-neutrals-gray-80, #4A4A4A);
  font-size: 16px;
  font-weight: 300;
  line-height: 150%;
}

.no-emoji {
  width: 100%;
  text-align: center;
  margin-top: 64px;
  color: var(--default-opaque-neutrals-gray-80, #4A4A4A);
  font-size: 16px;
  font-weight: 300;
  line-height: 150%;
}

.text {
  color: var(--default-opaque-neutrals-gray-80, #4A4A4A);
  font-size: 16px;
  font-weight: 300;
  line-height: 150%;
  width: 90%;
  max-width: 927px;

  & > *:not(:first-child) {
    margin-top: 16px;
  }
}

.gaps-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 64px;
  width: 100%;
  align-items: center;
}

@media screen and (max-width: 723px) {
  .button > span {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .row {
    gap: 24px;
  }
}

@media screen and (max-width: 555px) {
  .title {
    font-size: 46px;
  }
}

@media screen and (max-width: 527px) {
  .subtitle {
    display: none;
  }
}

@media screen and (max-width: 509px) {
  .button {
    height: 44px;
  }

  .button {
    padding-left: 9px;
    padding-right: 0;
  }

  .button:last-of-type {
    padding-right: 9px;
    padding-left: 0;
  }
}

@media screen and (max-width: 463px) {
  .week-subtitle {
    display: flex;
    flex-direction: column;
  }
}
