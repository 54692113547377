.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 32px;
}

@media screen and (max-width: 979px) {
  .container {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 700px) {
  .container {
    grid-template-columns: 1fr;
  }
}